<template>
  <div class="p-2">
    <!-- Tabs -->
    <b-tabs
      lazy
      active-tab-class="p-0 "
      pills
      nav-class="mb-0"
      active-nav-item-class="bg-info box-shadow-info border-info info"
    >
      <!-- Tab In Process -->
      <b-tab
        title="In Process"
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
      >
        <template v-if="$route.meta.programId === 3">
          <grid-in-process active :status="5" :tr="1" />
        </template>
        <template v-else>
          <grid-in-process
            active
            :status="5"
            :statusAccount="1"
            :idStandBy="1"
            :tr="1"
          />
        </template>
      </b-tab>
      <!-- Tab Completed -->
      <b-tab title="Completed" :title-link-class="[bgTabsNavs, 'sub-tab px-3']">
        <template v-if="$route.meta.programId === 3">
          <grid-in-process active :status="4" :tr="2" />
        </template>
        <template v-else>
          <grid-in-process
            :status="4"
            :statusAccount="1"
            :idStandBy="3"
            :tr="2"
          />
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// Import Component
import GridInProcess from "../grids/GridInProcess.vue";

export default {
  components: {
    "grid-in-process": GridInProcess,
  },
};
</script>
